import React from "react"
import Layout from "../components/layout"
import Thankspage from "../components/thankspage"
import { Container } from 'reactstrap'
import '../components/layout.css'

const Thanks = () => (
        <Layout>
            
            <Container>
                <Thankspage />
                
            </Container>
        </Layout>
)

export default Thanks
